<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable max-len -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/decompte'">
          Facture >
        </router-link>
        <span>Création de facture </span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Création de facture
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="row">
        <Notif :notif="notif" />
      </div>
      <form class="accordion" 
            id="accordionExample">  
        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingOne">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
              <th>Source de la facture</th>
            </tr>
          </thead>
          <div id="collapseOne"
               class="collapse show"
               aria-labelledby="headingOne"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>
                      Numéro de la lettre de commande <sup><span class="req-star">*</span></sup>
                    </label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <v-select :options="marches.donnees"
                            :reduce="marche => marche.id"
                            label="numero_marche"
                            ref="marche"
                            class="v-select"
                            @input="searchMarche(form.marche)"
                            v-model="form.marche"
                            placeholder="Choisir une lettre de commande">
                    <span slot="no-options"
                          @click="$refs.marche.open = false"
                          class="text-danger">
                      Aucune lettre de commande trouvé
                    </span>
                  </v-select>
                  <span v-if="form.errors().has('marche') && affiche.marche !== 'marche'"
                        v-text="form.errors().get('marche')"
                        class="errorMsg"> </span>
                </div>
              </div>
              <template v-if="marche.activite">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Ordonnancement <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select :options="marche.ordonnancements"
                              :reduce="ordonnancement =>ordonnancement.id"
                              label="annee_ordonnancement"
                              ref="marche"
                              class="v-select"
                              @input="searchSituation(form.marche,form.ordonnancement)"
                              v-model="form.ordonnancement"
                              placeholder="Choisir un ordonnancement">
                      <span slot="no-options"
                            @click="$refs.marche.open = false"
                            class="text-danger">
                        Aucun ordonnancement trouvé
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Programme</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.activite.rubrique.programme.libelle }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Rubrique</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.activite.rubrique.libelle }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Activité</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.activite.libelle }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Type d'entretien</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.type_entretien }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Département</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <span v-for="(departement,dekey) in marche.departement"
                          :key="dekey">{{ departement.libelle }}</span> <br>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Objet des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.objet }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date d'approbation</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_approbation_marche }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Lot</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.lot }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Entreprise</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.entreprise.libelle }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence ordre de service de démarrage</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.reference_ordre_service_demarrage }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date ordre de service de demarrage</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_ordre_service_demarrage }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de début</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_debut }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de fin</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_fin }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Délai d'exécution (mois)</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.delai_execution_marche }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date démarrage effectif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_demarrage_effectif }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date de fin des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_fin_travaux }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence ordre de service d'arrêt</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.reference_ordre_service_arret }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date ordre de service d'arrêt</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_ordre_service_arret }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Référence ordre de service de reprise des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.reference_ordre_service_reprise_travaux }}</div>
                </div>
                <div class="form-row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Date ordre de service de reprise des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">{{ marche.date_ordre_service_reprise_travaux }}</div>
                </div>
              </template>
            </div>
          </div>
        </table>

        <table class="table col-md-8 mx-auto"
               style="font-size: 0.6em;">
          <thead class="card-header"
                 id="headingTwo">
            <tr class="bg-black"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo">
              <th>Informations de la facture</th>
            </tr>
          </thead>
          <div id="collapseTwo"
               class="collapse"
               aria-labelledby="headingTwo"
               data-parent="#accordionExample">
            <div class="mt-3">
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Situation financière du marché</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  {{ form.situation_financiere_marche.toLocaleString() }} FCFA
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label>Situation financière de l'ordonnancement</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  {{ form.situation_financiere_ordonnancement.toLocaleString() }} FCFA
                </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label class="req">Numéro facture</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="text"
                         class="form-control"
                         v-on:click="removeSpanError('numero_decompte')"
                         v-on:change="verifierNumeroDecompte()"
                         v-model="form.numero_decompte" />
                  <span v-if="form.errors().has('numero_decompte') && affiche.numero_decompte !== 'numero_decompte'"
                         v-text="form.errors().get('numero_decompte')"
                         class="errorMsg"> </span>
                </div>
              </div>
              <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label class="req">Montant Facture</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           @input="addSpace('montant_facture_decompte')"
                           class="form-control"
                           v-on:click="removeSpanError('montant_facture_decompte')"
                           v-model="montant_facture_decompte" />
                    <span
                      v-if="form.errors().has('montant_facture_decompte') && affiche.montant_facture_decompte !== 'montant_facture_decompte'"
                      v-text="form.errors().get('montant_facture_decompte')"
                      class="errorMsg">
                    </span>
                  </div>
              </div>
              <div class="form-row row">
                <div class="col-md-4 form-group">
                  <h6>
                    <label class="req">Date Facture</label>
                  </h6>
                </div>
                <div class="col-md-4 form-group">
                  <input type="date"
                         class="form-control"
                         v-on:click="removeSpanError('date_facture')"
                         v-model="form.date_facture" />
                  <span v-if="form.errors().has('date_facture') && affiche.date_facture !== 'date_facture'"
                        v-text="form.errors().get('date_facture')"
                        class="errorMsg"> </span>
                </div>
              </div>    
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date établissement courrier d'appel de fonds</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_courrier_appel_fonds')"
                             v-model="form.date_etablissement_courrier_appel_fonds" />
                    <span v-if="form.errors().has('date_etablissement_courrier_appel_fonds') && affiche.date_etablissement_courrier_appel_fonds !== 'date_etablissement_courrier_appel_fonds'"
                          v-text="form.errors().get('date_etablissement_courrier_appel_fonds')"
                          class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence courrier d'appel de fonds
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_courrier_appel_fonds')"
                             v-model="form.reference_courrier_appel_fonds" />
                      <span
                          v-if="form.errors().has('reference_courrier_appel_fonds') && affiche.reference_courrier_appel_fonds !== 'reference_courrier_appel_fonds'"
                          v-text="form.errors().get('reference_courrier_appel_fonds')"
                          class="errorMsg"> 
                      </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Etablissement Courrier indication ligne supp. dépense</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_courrier_indication_ligne_supportant_depense')"
                             v-model="form.date_etablissement_courrier_indication_ligne_supportant_depense" />
                      <span v-if="form.errors().has('date_etablissement_courrier_indication_ligne_supportant_depense') && affiche.date_etablissement_courrier_indication_ligne_supportant_depense !== 'date_etablissement_courrier_indication_ligne_supportant_depense'"
                            v-text="form.errors().get('date_etablissement_courrier_indication_ligne_supportant_depense')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence Courrier d’indication ligne supp. dépense
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_courrier_indication_ligne_supportant_depense')"
                             v-model="form.reference_courrier_indication_ligne_supportant_depense" />
                      <span
                        v-if="form.errors().has('reference_courrier_indication_ligne_supportant_depense') && affiche.reference_courrier_indication_ligne_supportant_depense !== 'reference_courrier_indication_ligne_supportant_depense'"
                        v-text="form.errors().get('reference_courrier_indication_ligne_supportant_depense')"
                        class="errorMsg">
                      </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence Courrier de demande de proposition de prix
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                     <input type="text"
                            class="form-control"
                            v-on:click="removeSpanError('reference_courrier_demande_proposition_prix')"
                            v-model="form.reference_courrier_demande_proposition_prix" />
                    <span
                      v-if="form.errors().has('reference_courrier_demande_proposition_prix') && affiche.reference_courrier_demande_proposition_prix !== 'reference_courrier_demande_proposition_prix'"
                      v-text="form.errors().get('reference_courrier_demande_proposition_prix')"
                      class="errorMsg">
                    </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date établissement courrier demande proposition de prix</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                            class="form-control"
                            v-on:click="removeSpanError('date_etablissement_courrier_demande_proposition_prix')"
                            v-model="form.date_etablissement_courrier_demande_proposition_prix" />
                        <span v-if="form.errors().has('date_etablissement_courrier_demande_proposition_prix') && affiche.date_etablissement_courrier_demande_proposition_prix !== 'date_etablissement_courrier_demande_proposition_prix'"
                          v-text="form.errors().get('date_etablissement_courrier_demande_proposition_prix')"
                          class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date établissement courrier d'invitation  à une séance de négociation</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_courrier_invitation_seance_negociation')"
                             v-model="form.date_etablissement_courrier_invitation_seance_negociation" />
                      <span v-if="form.errors().has('date_etablissement_courrier_invitation_seance_negociation') && affiche.date_etablissement_courrier_invitation_seance_negociation !== 'date_etablissement_courrier_invitation_seance_negociation'"
                            v-text="form.errors().get('date_etablissement_courrier_invitation_seance_negociation')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence Courrier d’invitation à une séance de négociation 
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_courrier_invitation_seance_negociation')"
                             v-model="form.reference_courrier_invitation_seance_negociation" />
                      <span
                          v-if="form.errors().has('reference_courrier_invitation_seance_negociation') && affiche.reference_courrier_invitation_seance_negociation !== 'reference_courrier_invitation_seance_negociation'"
                          v-text="form.errors().get('reference_courrier_invitation_seance_negociation')"
                          class="errorMsg">
                      </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement du courrier de confirmation de prix</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_courrier_confirmation_prix')"
                             v-model="form.date_etablissement_courrier_confirmation_prix" />
                      <span v-if="form.errors().has('date_etablissement_courrier_confirmation_prix') && affiche.date_etablissement_courrier_confirmation_prix !== 'date_etablissement_courrier_confirmation_prix'"
                            v-text="form.errors().get('date_etablissement_courrier_confirmation_prix')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence de courrier de confirmation de prix 
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_courrier_confirmation_prix')"
                             v-model="form.reference_courrier_confirmation_prix" />
                    <span
                         v-if="form.errors().has('reference_courrier_confirmation_prix') && affiche.reference_courrier_confirmation_prix !== 'reference_courrier_confirmation_prix'"
                         v-text="form.errors().get('reference_courrier_confirmation_prix')"
                         class="errorMsg">
                    </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement du courrier de demande de cotation</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_courrier_demande_cotation')"
                             v-model="form.date_etablissement_courrier_demande_cotation" />
                     <span v-if="form.errors().has('date_etablissement_courrier_demande_cotation') && affiche.date_etablissement_courrier_demande_cotation !== 'date_etablissement_courrier_demande_cotation'"
                          v-text="form.errors().get('date_etablissement_courrier_demande_cotation')"
                          class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence demande de cotation 
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_demande_cotation')"
                             v-model="form.reference_demande_cotation" />
                    <span
                      v-if="form.errors().has('reference_demande_cotation') && affiche.reference_demande_cotation !== 'reference_demande_cotation'"
                      v-text="form.errors().get('reference_demande_cotation')"
                      class="errorMsg">
                    </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement du formulaire de selection</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_formulaire_selection')"
                             v-model="form.date_etablissement_formulaire_selection" />
                      <span v-if="form.errors().has('date_etablissement_formulaire_selection') && affiche.date_etablissement_formulaire_selection !== 'date_etablissement_formulaire_selection'"
                            v-text="form.errors().get('date_etablissement_formulaire_selection')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence formulaire de selection
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                            class="form-control"
                            v-on:click="removeSpanError('reference_formulaire_selection')"
                            v-model="form.reference_formulaire_selection" />
                    <span
                        v-if="form.errors().has('reference_formulaire_selection') && affiche.reference_formulaire_selection !== 'reference_formulaire_selection'"
                        v-text="form.errors().get('reference_formulaire_selection')"
                        class="errorMsg">
                    </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement du certificat de service fait</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_certificat_service_fait')"
                             v-model="form.date_etablissement_certificat_service_fait" />
                      <span v-if="form.errors().has('date_etablissement_certificat_service_fait') && affiche.date_etablissement_certificat_service_fait !== 'date_etablissement_certificat_service_fait'"
                            v-text="form.errors().get('date_etablissement_certificat_service_fait')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence certificat de service fait
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_certificat_service_fait')"
                             v-model="form.reference_certificat_service_fait" />
                      <span
                        v-if="form.errors().has('reference_certificat_service_fait') && affiche.reference_certificat_service_fait !== 'reference_certificat_service_fait'"
                        v-text="form.errors().get('reference_certificat_service_fait')"
                        class="errorMsg">
                      </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement du certificat pour paiement</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_certificat_pour_paiement')"
                             v-model="form.date_etablissement_certificat_pour_paiement" />
                    <span v-if="form.errors().has('date_etablissement_certificat_pour_paiement') && affiche.date_etablissement_certificat_pour_paiement !== 'date_etablissement_certificat_pour_paiement'"
                          v-text="form.errors().get('date_etablissement_certificat_pour_paiement')"
                          class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence du certificat pour paiement
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_certificat_paiement')"
                             v-model="form.reference_certificat_paiement" />
                      <span
                        v-if="form.errors().has('reference_certificat_paiement') && affiche.reference_certificat_paiement !== 'reference_certificat_paiement'"
                        v-text="form.errors().get('reference_certificat_paiement')"
                        class="errorMsg">
                      </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement du bon de livraison</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_bon_livraison')"
                             v-model="form.date_etablissement_bon_livraison" />
                      <span v-if="form.errors().has('date_etablissement_bon_livraison') && affiche.date_etablissement_bon_livraison !== 'date_etablissement_bon_livraison'"
                            v-text="form.errors().get('date_etablissement_bon_livraison')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement ordre mission</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_ordre_mission')"
                             v-model="form.date_etablissement_ordre_mission" />
                      <span v-if="form.errors().has('date_etablissement_ordre_mission') && affiche.date_etablissement_ordre_mission !== 'date_etablissement_ordre_mission'"
                          v-text="form.errors().get('date_etablissement_ordre_mission')"
                          class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence ordre de mission
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_ordre_mission')"
                             v-model="form.reference_ordre_mission" />
                      <span
                        v-if="form.errors().has('reference_ordre_mission') && affiche.reference_ordre_mission !== 'reference_ordre_mission'"
                        v-text="form.errors().get('reference_ordre_mission')"
                        class="errorMsg">
                      </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date établissement lettre d'invitation formation</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input  type="date"
                              class="form-control"
                              v-on:click="removeSpanError('date_etablissement_lettre_invitation_formation')"
                              v-model="form.date_etablissement_lettre_invitation_formation" />
                      <span v-if="form.errors().has('date_etablissement_lettre_invitation_formation') && affiche.date_etablissement_lettre_invitation_formation !== 'date_etablissement_lettre_invitation_formation'"
                          v-text="form.errors().get('date_etablissement_lettre_invitation_formation')"
                          class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Référence lettre d'invitation
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_lettre_invitation')"
                             v-model="form.reference_lettre_invitation" />
                      <span
                        v-if="form.errors().has('reference_lettre_invitation') && affiche.reference_lettre_invitation !== 'reference_lettre_invitation'"
                        v-text="form.errors().get('reference_lettre_invitation')"
                        class="errorMsg"
                      >
                      </span>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label for="">Date d'établissement du cautionnement AD</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date" 
                             class="form-control"
                             v-model="form.date_etablissement_cautionnement_ad"/>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Expiration de la caution AD</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <v-select
                        :options="[
                          {value:'date',libelle:'Date'},
                          {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                          {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                          {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                        ]"
                        :reduce="expiration => expiration.value"
                        label="libelle"
                        ref="expiration_ad"
                        id="expiration_ad"
                        v-model="expiration_ad"
                        @input="checkExpiration('expiration_caution_ad_texte','expiration_ad'),form.expiration_caution_ad_texte=expiration_ad"
                        placeholder="Choisir un type d'expiration"
                      >
                        <span slot="no-options"
                              @click="$refs.programme.open = false"
                              class="text-danger">
                          Aucune type
                        </span>
                      </v-select>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-if="expiration_ad=='date'"
                             v-model="form.expiration_caution_ad_date" />
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement du cautionnement RG</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-model="form.date_etablissement_cautionnement_rg" />
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Expiration de la caution RG</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <v-select
                      :options="[
                          {value:'date',libelle:'Date'},
                          {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                          {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                          {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                        ]"
                        :reduce="expiration => expiration.value"
                        label="libelle"
                        ref="expiration_rg"
                        id="expiration_rg"
                        v-model="expiration_rg"
                        @change="checkExpiration('expiration_caution_rg_texte','expiration_rg')"
                        placeholder="Choisir un type d'expiration"
                      >
                        <span slot="no-options"
                              @click="$refs.programme.open = false"
                              class="text-danger">
                          Aucune type
                        </span>
                      </v-select>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-if="expiration_rg=='date'"
                             v-model="form.expiration_caution_rg_date" />
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label for="">Date d'établissement du cautionnement définitif</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date" 
                             class="form-control"
                             v-model="form.date_etablissement_cautionnement_definitif"/>
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label for="">Date d'expiration du cautionnement définitif</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <v-select
                        :options="[
                          {value:'date',libelle:'Date'},
                          {value:'Remboursement intégrale de l\'avance démarrage',libelle:'Remboursement intégrale de l\'avance démarrage'},
                          {value:'Reception provisoire des travaux',libelle:'Reception provisoire des travaux'},
                          {value:'Reception definitive des travaux',libelle:'Reception definitive des travaux'},
                        ]"
                        :reduce="expiration => expiration.value"
                        label="libelle"
                        ref="expiration_definitif"
                        id="expiration_definitif"
                        v-model="expiration_definitif"
                        @change="checkExpiration('expiration_cautionnement_definitif_texte','expiration_definitif')"
                        placeholder="Choisir un type d'expiration"
                      >
                        <span slot="no-options"
                              @click="$refs.programme.open = false"
                              class="text-danger">
                          Aucune type
                        </span>
                      </v-select>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-if="expiration_definitif=='date'"
                             v-model="form.expiration_cautionnement_definitif_date" />
                    </div>
                  </div>
                
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label class="req">Référence Facture</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-on:click="removeSpanError('reference_facture')"
                             v-model="form.reference_facture" />
                      <span v-if="form.errors().has('reference_facture') && affiche.reference_facture !== 'reference_facture'"
                            v-text="form.errors().get('reference_facture')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement  du certificat de participation</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                          class="form-control"
                          v-on:click="removeSpanError('date_etablissement_certif_participation')"
                          v-model="form.date_etablissement_certif_participation" />
                      <span v-if="form.errors().has('date_etablissement_certif_participation') && affiche.date_etablissement_certif_participation !== 'date_etablissement_certif_participation'"
                            v-text="form.errors().get('date_etablissement_certif_participation')"
                            class="errorMsg"> </span>
                     </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date d'établissement du procès verbal de reception des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-on:click="removeSpanError('date_etablissement_proces_verbal_reception_travaux')"
                             v-model="form.date_etablissement_proces_verbal_reception_travaux" />
                      <span v-if="form.errors().has('date_etablissement_proces_verbal_reception_travaux') && affiche.date_etablissement_proces_verbal_reception_travaux !== 'date_etablissement_proces_verbal_reception_travaux'"
                            v-text="form.errors().get('date_etablissement_proces_verbal_reception_travaux')"
                            class="errorMsg"> </span>
                     </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label class="req">Date d'établissement du décompte</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input  type="date"
                              class="form-control"
                              v-on:click="removeSpanError('date_etablissement_decompte')"
                              v-model="form.date_etablissement_decompte" />
                        <span v-if="form.errors().has('date_etablissement_decompte') && affiche.date_etablissement_decompte !== 'date_etablissement_decompte'"
                              v-text="form.errors().get('date_etablissement_decompte')"
                    class="errorMsg"> </span>
                     </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>Date de l'Attachement/constat des travaux</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                    <input type="date"
                          class="form-control"
                          v-on:click="removeSpanError('date_attachement_constat_travaux')"
                          v-model="form.date_attachement_constat_travaux" />
                    <span v-if="form.errors().has('date_attachement_constat_travaux') && affiche.date_attachement_constat_travaux !== 'date_attachement_constat_travaux'"
                          v-text="form.errors().get('date_attachement_constat_travaux')"
                          class="errorMsg"> </span>
                     </div>
                  </div>
                </div>
            </div>
          </table>
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingFour">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                <th>Rapports</th>
              </tr>
            </thead>
            <div id="collapseFour"
                 class="collapse"
                 aria-labelledby="headingFour"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="col-md-12"
                     v-for="(item, index) in rapports"
                     :key="index">
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label class="">Rapport n°{{ index }}</label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <div class="custom-file">
                        <label class="custom-file-label">
                          {{ item.rapport }}
                        </label>
                        <input
                          type="file"
                          :name="'rapport'+index"
                          class="custom-file-input"
                          :id="'rapport'+index"
                          :ref="'rapport'+index"
                          v-on:change="handleRapportUpload(index)"
                          accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                          :disabled="disabled"
                        />
                        <span v-if="form.errors().has('ordre_service_prorogation_delai') && affiche.ordre_service_prorogation_delai !== 'ordre_service_prorogation_delai'"
                              v-text="form.errors().get('ordre_service_prorogation_delai')"
                              class="errorMsg"> </span><br />
                        <span class="listExtension"> Format :
                          <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                          </span>
                        </span>
                      </div>
                    </div>
                    <span class="errorMsg"> </span>
                    <div class="form-group col-md-1 pt-2 my-auto">
                      <Loader v-show="loading.ordre_service_prorogation_delai" />
                    </div>
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label>
                          Date du rapport
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="date"
                             class="form-control"
                             v-model="form.rapports[index].date_rapport"
                             :name="`data[${index}]date_rapport`"/>
                    </div>
                  </div>
                  
                </div>
                <div class="row mb-3">
                  <button type="button"
                          class="btn btn-f-blue"
                          v-on:click="rapports.push({rapport:'',date_rapport:''}),form.rapports.push({rapport:'',date_rapport:''})">+</button>
                  <button type="button" 
                          class="btn btn-f-blue"
                          v-on:click="rapports.splice(rapports.length-1, 1),form.rapports.splice(form.rapports.length-1, 1)">-</button>
                </div>
                
              </div>
            </div>
          </table> 
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
                 <thead class="card-header"
                   id="headingThree">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree">
                <th>Fichiers à télécharger</th>
              </tr>
            </thead>
            <div id="collapseThree"
                 class="collapse"
                 aria-labelledby="headingThree"
                 data-parent="#accordionExample">
                 <div class="mt-3">
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Courrier d’indication de la ligne de la convention supportant la dépense
                          </label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">
                            {{ inputFiles.courrier_indication_ligne_lcd }}
                          </label>
                          <input
                            type="file"
                            name="courrier_indication_ligne_lcd"
                            class="custom-file-input"
                            id="courrier_indication_ligne_lcd"
                            ref="courrier_indication_ligne_lcd"
                            v-on:click="removeSpanError('courrier_indication_ligne_lcd')"
                            v-on:change="handleFileUpload('courrier_indication_ligne_lcd')"/>
                            <span
                              v-if="form.errors().has('courrier_indication_ligne_lcd') && affiche.courrier_indication_ligne_lcd !== 'courrier_indication_ligne_lcd'"
                              v-text="form.errors().get('courrier_indication_ligne_lcd')"
                              class="errorMsg"
                            >
                            </span>
                            <br />
                            <span class="listExtension"> Format :
                              <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                              </span>
                            </span>
                          </div>
                      </div>
                      <div class="form-group col-md-1 pt-2 my-auto">
                        <Loader v-show="loading.courrier_indication_ligne_lcd" />
                      </div>
                    </div>

                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Demande de proposition de prix</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.demande_proposition_prix }}</label>
                          <input
                            type="file"
                            name="demande_proposition_prix"
                            class="custom-file-input"
                            id="demande_proposition_prix"
                            ref="demande_proposition_prix"
                            v-on:click="removeSpanError('demande_proposition_prix')"
                            v-on:change="handleFileUpload('demande_proposition_prix')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"
                          />
                          <span
                            v-if="form.errors().has('demande_proposition_prix') && affiche.demande_proposition_prix !== 'demande_proposition_prix'"
                            v-text="form.errors().get('demande_proposition_prix')"
                            class="errorMsg"
                          >
                          </span>
                          <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                            </span>
                          </div>
                        </div>
                      <div class="form-group col-md-1 pt-2 my-auto">
                        <Loader v-show="loading.demande_proposition_prix" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Courrier de confirmation de prix</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.courrier_confirmation_prix }}</label>
                          <input
                            type="file"
                            name="courrier_confirmation_prix"
                            id="courrier_confirmation_prix"
                            ref="courrier_confirmation_prix"
                            class="custom-file-input"
                            v-on:click="removeSpanError('courrier_confirmation_prix')"
                            v-on:change="handleFileUpload('courrier_confirmation_prix')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"
                          />
                          <span
                            v-if="form.errors().has('courrier_confirmation_prix') && affiche.courrier_confirmation_prix !== 'courrier_confirmation_prix'"
                            v-text="form.errors().get('courrier_confirmation_prix')"
                            class="errorMsg"
                          >
                          </span>
                          <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        </div>
                      <div class="form-group col-md-1 pt-2 my-auto">
                        <Loader v-show="loading.courrier_confirmation_prix" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Courrier d’invitation à une séance de négociation</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                            <label class="custom-file-label">{{ inputFiles.courrier_invitation_sn }}</label>
                            <input
                              type="file"
                              name="courrier_invitation_sn"
                              id="courrier_invitation_sn"
                              ref="courrier_invitation_sn"
                              class="custom-file-input"
                              v-on:click="removeSpanError('courrier_invitation_sn')"
                              v-on:change="handleFileUpload('courrier_invitation_sn')"
                              accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                              :disabled="disabled"
                            />
                            <span
                              v-if="form.errors().has('courrier_invitation_sn') && affiche.courrier_invitation_sn !== 'courrier_invitation_sn'"
                              v-text="form.errors().get('courrier_invitation_sn')"
                              class="errorMsg"
                            >
                            </span>
                            <br />
                            <span class="listExtension"> Format :
                              <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                              </span>
                            </span>
                          </div>
                        </div>
                      <div class="form-group col-md-1 pt-2 my-auto">
                        <Loader v-show="loading.courrier_invitation_sn" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Bon de livraison</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                            <label class="custom-file-label">{{ inputFiles.bon_livraison }}</label>
                            <input
                              type="file"
                              name="bon_livraison"
                              id="bon_livraison"
                              ref="bon_livraison"
                              class="custom-file-input"
                              v-on:click="removeSpanError('bon_livraison')"
                              v-on:change="handleFileUpload('bon_livraison')"
                              accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                              :disabled="disabled"
                            />
                            <span v-if="form.errors().has('bon_livraison') && affiche.bon_livraison !== 'bon_livraison'"
                                  v-text="form.errors().get('bon_livraison')"
                                  class="errorMsg"> </span> <br />
                            <span class="listExtension"> Format :
                              <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                              </span>
                            </span>
                          </div>
                        </div>
                      <div class="form-group col-md-1 pt-2 my-auto">
                        <Loader v-show="loading.bon_livraison" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Certificat de service fait</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.certificat_service_fait }}</label>
                          <input
                            type="file"
                            name="certificat_service_fait"
                            id="certificat_service_fait"
                            ref="certificat_service_fait"
                            class="custom-file-input"
                            v-on:click="removeSpanError('certificat_service_fait')"
                            v-on:change="handleFileUpload('certificat_service_fait')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"
                          />
                          <span
                            v-if="form.errors().has('certificat_service_fait') && affiche.certificat_service_fait !== 'certificat_service_fait'"
                            v-text="form.errors().get('certificat_service_fait')"
                            class="errorMsg"
                          >
                          </span>
                          <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.certificat_service_fait" />
                      </div>
                    </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Facture<sup><span class="req-star">*</span></sup></label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.facture }}</label>
                          <input
                            type="file"
                            name="facture"
                            id="facture"
                            ref="facture"
                            class="custom-file-input"
                            v-on:click="removeSpanError('facture')"
                            v-on:change="handleFileUpload('facture')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"
                          />
                          <span v-if="form.errors().has('facture') && affiche.facture !== 'facture'"
                                v-text="form.errors().get('facture')"
                                class="errorMsg"> </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.facture" />
                      </div>
                    </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Courrier d'appel de fonds</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.courrier_appel_fonds }}</label>
                          <input
                            type="file"
                            name="courrier_appel_fonds"
                            id="courrier_appel_fonds"
                            ref="courrier_appel_fonds"
                            class="custom-file-input"
                            v-on:click="removeSpanError('courrier_appel_fonds')"
                            v-on:change="handleFileUpload('courrier_appel_fonds')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"
                          />
                          <span v-if="form.errors().has('courrier_appel_fonds') && affiche.courrier_appel_fonds !== 'courrier_appel_fonds'"
                                v-text="form.errors().get('courrier_appel_fonds')"
                                class="errorMsg"> </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.courrier_appel_fonds" />
                      </div>
                    </div>
                    </div>
                    <!-- <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Courrier indication ligne supportant dépense</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.courrier_indication_ligne_supportant_depense }}</label>
                          <input
                            type="file"
                            name="courrier_indication_ligne_supportant_depense"
                            id="courrier_indication_ligne_supportant_depense"
                            ref="courrier_indication_ligne_supportant_depense"
                            class="custom-file-input"
                            v-on:click="removeSpanError('courrier_indication_ligne_supportant_depense')"
                            v-on:change="handleFileUpload('courrier_indication_ligne_supportant_depense')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('courrier_indication_ligne_supportant_depense') && affiche.courrier_indication_ligne_supportant_depense !== 'courrier_indication_ligne_supportant_depense'"
                                v-text="form.errors().get('courrier_indication_ligne_supportant_depense')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.courrier_indication_ligne_supportant_depense" />
                      </div>
                    </div>
                    </div> -->
                    <!-- <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Courrier de demande proposition de prix</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.courrier_demande_proposition_prix }}</label>
                          <input
                            type="file"
                            name="courrier_demande_proposition_prix"
                            id="courrier_demande_proposition_prix"
                            ref="courrier_demande_proposition_prix"
                            class="custom-file-input"
                            v-on:click="removeSpanError('courrier_demande_proposition_prix')"
                            v-on:change="handleFileUpload('courrier_demande_proposition_prix')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('courrier_demande_proposition_prix') && affiche.courrier_demande_proposition_prix !== 'courrier_demande_proposition_prix'"
                                v-text="form.errors().get('courrier_demande_proposition_prix')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.courrier_demande_proposition_prix" />
                      </div>
                    </div>
                    </div> -->
                    
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Formulaire de demande de cotation</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.formulaire_demande_cotation }}</label>
                          <input
                            type="file"
                            name="formulaire_demande_cotation"
                            id="formulaire_demande_cotation"
                            ref="formulaire_demande_cotation"
                            class="custom-file-input"
                            v-on:click="removeSpanError('formulaire_demande_cotation')"
                            v-on:change="handleFileUpload('formulaire_demande_cotation')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('formulaire_demande_cotation') && affiche.formulaire_demande_cotation !== 'formulaire_demande_cotation'"
                                v-text="form.errors().get('formulaire_demande_cotation')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.formulaire_demande_cotation" />
                      </div>
                    </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Formulaire de sélection</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.formulaire_selection }}</label>
                          <input
                            type="file"
                            name="formulaire_selection"
                            id="formulaire_selection"
                            ref="formulaire_selection"
                            class="custom-file-input"
                            v-on:click="removeSpanError('formulaire_selection')"
                            v-on:change="handleFileUpload('formulaire_selection')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('formulaire_selection') && affiche.formulaire_selection !== 'formulaire_selection'"
                                v-text="form.errors().get('formulaire_selection')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.formulaire_selection" />
                      </div>
                    </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Certificat pour paiement</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.certificat_paiement }}</label>
                          <input
                            type="file"
                            name="certificat_paiement"
                            id="certificat_paiement"
                            ref="certificat_paiement"
                            class="custom-file-input"
                            v-on:click="removeSpanError('certificat_paiement')"
                            v-on:change="handleFileUpload('certificat_paiement')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('certificat_paiement') && affiche.certificat_paiement !== 'certificat_paiement'"
                                v-text="form.errors().get('certificat_paiement')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.certificat_paiement" />
                      </div>
                    </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Tableau récapitulatif dépenses effectuées demande précédente</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.tableau_recap_depense_precedente }}</label>
                          <input
                            type="file"
                            name="tableau_recap_depense_precedente"
                            id="tableau_recap_depense_precedente"
                            ref="tableau_recap_depense_precedente"
                            class="custom-file-input"
                            v-on:click="removeSpanError('tableau_recap_depense_precedente')"
                            v-on:change="handleFileUpload('tableau_recap_depense_precedente')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('tableau_recap_depense_precedente') && affiche.tableau_recap_depense_precedente !== 'tableau_recap_depense_precedente'"
                                v-text="form.errors().get('tableau_recap_depense_precedente')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.tableau_recap_depense_precedente" />
                      </div>
                    </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Ordre de mission</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                        <label class="custom-file-label">{{ inputFiles.ordre_mission }}</label>
                        <input
                          type="file"
                          name="ordre_mission"
                          id="ordre_mission"
                          ref="ordre_mission"
                          class="custom-file-input"
                          v-on:click="removeSpanError('ordre_mission')"
                          v-on:change="handleFileUpload('ordre_mission')"
                          accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                          :disabled="disabled"/>
                      <span v-if="form.errors().has('ordre_mission') && affiche.ordre_mission !== 'ordre_mission'"
                              v-text="form.errors().get('ordre_mission')"
                              class="errorMsg"> 
                        </span> <br />
                        <span class="listExtension"> Format :
                          <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                          </span>
                        </span>
                      </div>
                      </div>
                      
                      <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.ordre_mission" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Pièces justificatives des dépenses effectuées sur les appels de fonds précedent</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                          <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.pieces_justif_depenses_appels_fonds_precedent }}</label>
                          <input
                            type="file"
                            name="pieces_justif_depenses_appels_fonds_precedent"
                            id="pieces_justif_depenses_appels_fonds_precedent"
                            ref="pieces_justif_depenses_appels_fonds_precedent"
                            class="custom-file-input"
                            v-on:click="removeSpanError('pieces_justif_depenses_appels_fonds_precedent')"
                            v-on:change="handleFileUpload('pieces_justif_depenses_appels_fonds_precedent')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('pieces_justif_depenses_appels_fonds_precedent') && affiche.pieces_justif_depenses_appels_fonds_precedent !== 'pieces_justif_depenses_appels_fonds_precedent'"
                                v-text="form.errors().get('pieces_justif_depenses_appels_fonds_precedent')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                          </div>
                        </div>
                      <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.pieces_justif_depenses_appels_fonds_precedent" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Lettre d’invitation à la formation</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                        <label class="custom-file-label">{{ inputFiles.lettre_invitation_formation }}</label>
                        <input
                          type="file"
                          name="lettre_invitation_formation"
                          id="lettre_invitation_formation"
                          ref="lettre_invitation_formation"
                          class="custom-file-input"
                          v-on:click="removeSpanError('lettre_invitation_formation')"
                          v-on:change="handleFileUpload('lettre_invitation_formation')"
                          accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                          :disabled="disabled"/>
                      <span v-if="form.errors().has('lettre_invitation_formation') && affiche.lettre_invitation_formation !== 'lettre_invitation_formation'"
                              v-text="form.errors().get('lettre_invitation_formation')"
                              class="errorMsg"> 
                        </span> <br />
                        <span class="listExtension"> Format :
                          <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                          </span>
                        </span>
                       </div>
                      </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.lettre_invitation_formation" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Pièces justificatives dépenses engagées pendant la formation </label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.pieces_justificatives_depenses_engagees_pendant_formation }}</label>
                          <input
                            type="file"
                            name="pieces_justificatives_depenses_engagees_pendant_formation"
                            id="pieces_justificatives_depenses_engagees_pendant_formation"
                            ref="pieces_justificatives_depenses_engagees_pendant_formation"
                            class="custom-file-input"
                            v-on:click="removeSpanError('pieces_justificatives_depenses_engagees_pendant_formation')"
                            v-on:change="handleFileUpload('pieces_justificatives_depenses_engagees_pendant_formation')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('pieces_justificatives_depenses_engagees_pendant_formation') && affiche.pieces_justificatives_depenses_engagees_pendant_formation !== 'pieces_justificatives_depenses_engagees_pendant_formation'"
                                v-text="form.errors().get('pieces_justificatives_depenses_engagees_pendant_formation')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.pieces_justificatives_depenses_engagees_pendant_formation" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Certificat de participation à la formation/séminaire/atélier/mission</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.certificat_participation_formation_seminaire_atelier_mission }}</label>
                          <input
                            type="file"
                            name="certificat_participation_formation_seminaire_atelier_mission"
                            id="certificat_participation_formation_seminaire_atelier_mission"
                            ref="certificat_participation_formation_seminaire_atelier_mission"
                            class="custom-file-input"
                            v-on:click="removeSpanError('certificat_participation_formation_seminaire_atelier_mission')"
                            v-on:change="handleFileUpload('certificat_participation_formation_seminaire_atelier_mission')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                          <span v-if="form.errors().has('certificat_participation_formation_seminaire_atelier_mission') && affiche.certificat_participation_formation_seminaire_atelier_mission !== 'certificat_participation_formation_seminaire_atelier_mission'"
                                v-text="form.errors().get('certificat_participation_formation_seminaire_atelier_mission')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                          </div>
                        </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.certificat_participation_formation_seminaire_atelier_mission" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Cautionnement d'Avance de Démarrage </label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.cautionnement_avance_demarrage }}</label>
                          <input
                            type="file"
                            name="cautionnement_avance_demarrage"
                            id="cautionnement_avance_demarrage"
                            ref="cautionnement_avance_demarrage"
                            class="custom-file-input"
                            v-on:click="removeSpanError('cautionnement_avance_demarrage')"
                            v-on:change="handleFileUpload('cautionnement_avance_demarrage')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('cautionnement_avance_demarrage') && affiche.cautionnement_avance_demarrage !== 'cautionnement_avance_demarrage'"
                                v-text="form.errors().get('cautionnement_avance_demarrage')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.cautionnement_avance_demarrage" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Retenue de Garantie</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.retenue_garantie }}</label>
                          <input
                            type="file"
                            name="retenue_garantie"
                            id="retenue_garantie"
                            ref="retenue_garantie"
                            class="custom-file-input"
                            v-on:click="removeSpanError('retenue_garantie')"
                            v-on:change="handleFileUpload('retenue_garantie')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('retenue_garantie') && affiche.retenue_garantie !== 'retenue_garantie'"
                                v-text="form.errors().get('retenue_garantie')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                           </span>
                           </div>
                        </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.retenue_garantie" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Cautionnement définitif</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.caution_definitif }}</label>
                          <input
                            type="file"
                            name="caution_definitif"
                            id="caution_definitif"
                            ref="caution_definitif"
                            class="custom-file-input"
                            v-on:click="removeSpanError('caution_definitif')"
                            v-on:change="handleFileUpload('caution_definitif')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('caution_definitif') && affiche.caution_definitif !== 'caution_definitif'"
                                v-text="form.errors().get('caution_definitif')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.caution_definitif" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Proces verbal de reception des travaux</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                        <label class="custom-file-label">{{ inputFiles.proces_verbal_reception_travaux }}</label>
                        <input
                          type="file"
                          name="proces_verbal_reception_travaux"
                          id="proces_verbal_reception_travaux"
                          ref="proces_verbal_reception_travaux"
                          class="custom-file-input"
                          v-on:click="removeSpanError('proces_verbal_reception_travaux')"
                          v-on:change="handleFileUpload('proces_verbal_reception_travaux')"
                          accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                          :disabled="disabled"/>
                      <span v-if="form.errors().has('proces_verbal_reception_travaux') && affiche.proces_verbal_reception_travaux !== 'proces_verbal_reception_travaux'"
                              v-text="form.errors().get('proces_verbal_reception_travaux')"
                              class="errorMsg"> 
                        </span> <br />
                        <span class="listExtension"> Format :
                          <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                          </span>
                        </span>
                          </div>
                        </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.proces_verbal_reception_travaux" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Décompte<sup><span class="req-star">*</span></sup></label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.decompte }}</label>
                          <input
                            type="file"
                            name="decompte"
                            id="decompte"
                            ref="decompte"
                            class="custom-file-input"
                            v-on:click="removeSpanError('decompte')"
                            v-on:change="handleFileUpload('decompte')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('decompte') && affiche.decompte !== 'decompte'"
                                v-text="form.errors().get('decompte')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                        </div>
                        </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.decompte" />
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label for="">Attachement y compris constat des travaux </label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <div class="custom-file">
                          <label class="custom-file-label">{{ inputFiles.attachement_y_compris_travaux }}</label>
                          <input
                            type="file"
                            name="attachement_y_compris_travaux"
                            id="attachement_y_compris_travaux"
                            ref="attachement_y_compris_travaux"
                            class="custom-file-input"
                            v-on:click="removeSpanError('attachement_y_compris_travaux')"
                            v-on:change="handleFileUpload('attachement_y_compris_travaux')"
                            accept=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.doc"
                            :disabled="disabled"/>
                        <span v-if="form.errors().has('attachement_y_compris_travaux') && affiche.attachement_y_compris_travaux !== 'attachement_y_compris_travaux'"
                                v-text="form.errors().get('attachement_y_compris_travaux')"
                                class="errorMsg"> 
                          </span> <br />
                          <span class="listExtension"> Format :
                            <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }}
                            </span>
                          </span>
                          </div>
                        </div>
                       <div class="form-group col-md-1 pt-2 my-auto">
                          <Loader v-show="loading.attachement_y_compris_travaux" />
                      </div>
                    </div>
                    
                    <div class="form-row row">
                      <div class="col-md-4 form-group">
                        <h6>
                          <label>Observations</label>
                        </h6>
                      </div>
                      <div class="col-md-4 form-group">
                        <textarea class="form-control"
                              rows="3"
                              v-on:click="removeSpanError('observation')"
                              v-model="form.observation"> </textarea>
                    <span v-if="form.errors().has('observation') && affiche.observation !== 'observation'"
                          v-text="form.errors().get('observation')"
                          class="errorMsg"> </span>
                      </div>
                    </div>
                </div>
              </div>
          </table>
          <div class="form-row row mt-4">
            <div class="col-md-8 offset-sm-5">
              <div class="form-group">
                <div class="button_form">
                  <button type="button"
                          class="btn btn-f-blue enter_btn"
                          @click="submit">
                    <i class="flaticon flaticon-folder"></i>
                    Envoyer
                  </button>
            <!-- <button type="button"
                    class="btn btn-f-blue enter_btn"
                    @click="saving()">
              <i class="flaticon flaticon-floppy-disk"></i>
              Enregistrer
            </button> -->
                  <button type="reset"
                          class="btn btn-f-blue cancle_btn"
                          data-toggle="modal"
                          data-target="#exampleModal">
                    <i class="flaticon flaticon-cancel"></i>
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
      
        </form>

        <!-- modal box -->
        <div class="modal fade"
             id="exampleModal"
             tabindex="-1"
             role="dialog"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog"
               role="document">
            <div class="modal-content">
              <div class="modal-header ">
                <h5 class="modal-title text-uppercase"
                    id="exampleModalLabel">Annulation</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Cette action est irréversible, êtes-vous sure de vouloir annuler ?</p>
              </div>
              <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal">Non</button>
                <button type="button"
                        @click="goBack()"
                        data-dismiss="modal"
                        class="btn btn-primary">Oui</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 </div>
</template>
<!-- <style>
@import "./decompte.css";
.listExtension {
  font-size: 0.8em;
}
form.form_default label{
  font-size: 0.7em;
  color: black;
}
button.btn-f-blue {
  /* width: 15%; */
  border-radius: none;
  font-size: 0.8em;
}
div ::placeholder {
  color: rgba(220, 220, 220, 0.897);
}
.fileExtentions{
  color: #0d6efd;
} 
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.8em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}

form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: fit-content;
  }
}
</style> -->
<script>
import form from "vuejs-form"
import Loader from "@/components/shared/Spinner"
import Notif from "@/components/shared/Toast"
import VSelect from "vue-select"
import 'vue-select/dist/vue-select.css'
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"
export default {
  name: "CreateDecompte",
  components: {
    Loader,
    Notif,
    VSelect
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    rapports:[{rapport:"",date_rapport:""}],

    montant_ordonnancement: "",
    montant_facture_decompte: "",
    montant_decompte:"",
    expiration_ad:"date",
    expiration_rg:"date",
    expiration_definitif:"date",
    form: form({
      marche: "",
      ordonnancement:"",
      numero_decompte: "",
      // sous_traitant: "",
      
      type_decompte: "decompte_travaux_dgir",
      courrier_indication_ligne_lcd: "",
      demande_proposition_prix: "",
      courrier_invitation_sn: "",
      courrier_confirmation_prix: "",
      bon_livraison: "",
      certificat_service_fait: "",
      situation_financiere_marche: "",
      situation_financiere_ordonnancement: "",
      facture: "",
      montant_facture_decompte: "",
      reference_facture: "",
      date_facture: "",
      observation: "",
      date_etablissement_courrier_appel_fonds:"",
      reference_courrier_appel_fonds:"",
      date_etablissement_courrier_indication_ligne_supportant_depense:"",
      reference_courrier_indication_ligne_supportant_depense:"",
      reference_courrier_demande_proposition_prix:"",
      date_etablissement_courrier_demande_proposition_prix:"",
      date_etablissement_courrier_invitation_seance_negociation:"",
      reference_courrier_invitation_seance_negociation:"",
      date_etablissement_courrier_confirmation_prix:"",
      reference_courrier_confirmation_prix:"",
      date_etablissement_courrier_demande_cotation:"",
      reference_demande_cotation:"",
      date_etablissement_formulaire_selection:"",
      reference_formulaire_selection:"",
      date_etablissement_certificat_service_fait:"",
      reference_certificat_service_fait:"",
      date_etablissement_certificat_pour_paiement:"",
      reference_certificat_paiement:"",
      date_etablissement_bon_livraison:"",
      date_etablissement_ordre_mission:"",
      reference_ordre_mission:"",
      date_etablissement_lettre_invitation_formation:"",
      reference_lettre_invitation:"",
      expiration_caution_ad_date: "",
      expiration_caution_ad_texte: "",
      expiration_caution_rg_date: "",
      expiration_caution_rg_texte: "",
      expiration_cautionnement_definitif_date: "",
      expiration_cautionnement_definitif_texte: "",
      date_etablissement_certif_participation:"",
      date_etablissement_cautionnement_ad:"",
      date_etablissement_cautionnement_rg:"",
      date_etablissement_cautionnement_definitif:"",
      date_etablissement_proces_verbal_reception_travaux:"",
      date_etablissement_decompte:"",
      date_attachement_constat_travaux:"",
      courrier_appel_fonds:"",
      courrier_indication_ligne_supportant_depense:"",
      courrier_demande_proposition_prix:"",
      formulaire_demande_cotation:"",
      formulaire_selection: "",
      certificat_paiement:"",
      rapport_activite:"",
      tableau_recap_depense_precedente:"",
      ordre_mission: "",
      pieces_justif_depenses_appels_fonds_precedent: "",
      lettre_invitation_formation: "",
      pieces_justificatives_depenses_engagees_pendant_formation: "",
      certificat_participation_formation_seminaire_atelier_mission: "",
      cautionnement_avance_demarrage: "",
      retenue_garantie: "",
      caution_definitif: "",
      proces_verbal_reception_travaux: "",
      attachement_y_compris_travaux: "",
      rapports:[{rapport:"",date_rapport:""}],
      decompte:"",
    })
      .rules({
        marche: "required",
        numero_decompte: "required",
        // sous_traitant: "required",
        montant_facture_decompte: "required",
        reference_facture: "required",
        date_facture: "required",
        // observation: "required"
      })
      .messages({
        marche: "Champ requis!",
        numero_decompte: "Champ requis!",
        // sous_traitant: "Champ requis!",
        type_entretien: "Champ requis!",
        reference_lettre_commande: "Champ requis!",
        date_lettre_commande: "Champ requis!",
        date_ordre_service: "Champ requis!",
        date_demarrage_effectif: "date_demarrage_effectif",
        courrier_indication_ligne_lcd: "Champ requis!",
        demande_proposition_prix: "Champ requis!",
        courrier_invitation_sn: "Champ requis!",
        courrier_confirmation_prix: "Champ requis!",
        bon_livraison: "Champ requis!",
        certificat_service_fait: "Champ requis!",
        situation_financiere_marche: "Champ requis!",
        facture: "Champ requis!",
        montant_facture_decompte: "Champ requis!",
        reference_facture: "Champ requis!",
        date_facture: "Champ requis!",
        // observation: "Champ requis!"
      }),
    marcheData: {
      limite: 100,
      avant: "MQ=="
    },
    uploadfile: "courrier_indication_ligne_lcd",
    gmarche: "",
    disabled: false,
    loading: {
      courrier_indication_ligne_lcd: false,
      courrier_appel_fonds:false,
      demande_proposition_prix: false,
      courrier_invitation_sn: false,
      courrier_confirmation_prix: false,
      bon_livraison: false,
      certificat_service_fait: false,
      situation_financiere_marche: false,
      facture: false,
      formulaire_demande_cotation:false,
      formulaire_selection:false,
      certificat_paiement:false,
      tableau_recap_depense_precedente:false,
      ordre_mission:false,
      pieces_justif_depenses_appels_fonds_precedent:false,
      lettre_invitation_formation:false,
      pieces_justificatives_depenses_engagees_pendant_formation:false,
      certificat_participation_formation_seminaire_atelier_mission:false,
      cautionnement_avance_demarrage:false,
      retenue_garantie:false,
      caution_definitif:false,
      proces_verbal_reception_travaux: false,
      decompte:false,
      attachement_y_compris_travaux:false,
    },
    inputFiles: {
      courrier_indication_ligne_lcd: "",
      courrier_appel_fonds:"",
      demande_proposition_prix: "",
      courrier_invitation_sn: "",
      courrier_confirmation_prix: "",
      bon_livraison: "",
      certificat_service_fait: "",
      situation_financiere_marche: "",
      facture: "",
      formulaire_demande_cotation:"",
      formulaire_selection:"",
      certificat_paiement:"",
      tableau_recap_depense_precedente:"",
      ordre_mission:"",
      pieces_justif_depenses_appels_fonds_precedent:"",
      lettre_invitation_formation:"",
      pieces_justificatives_depenses_engagees_pendant_formation:"",
      certificat_participation_formation_seminaire_atelier_mission:"",
      cautionnement_avance_demarrage:"",
      retenue_garantie:"",
      caution_definitif:"",
      proces_verbal_reception_travaux: "",
      decompte:"",
      attachement_y_compris_travaux:"",
    },
    listExtensionFiles: [],
    //La liste des decomptes deja utilisé
    listNumeroDecompte:[],
    affiche: {
      marche: "marche:true",
      numero_decompte: "numero_decompte:true",
      sous_traitant: "sous_traitant:true",
      type_entretien: "type_entretien:true",
      type_decompte: "type_decompte:true",
      reference_lettre_commande: "reference_lettre_commande:true",
      date_lettre_commande: "date_lettre_commande:true",
      date_ordre_service: "date_ordre_service:true",
      date_demarrage_effectif: "date_demarrage_effectif:true",
      courrier_indication_ligne_lcd: "courrier_indication_ligne_lcd:true",
      demande_proposition_prix: "demande_proposition_prix:true",
      courrier_invitation_sn: "courrier_invitation_sn:true",
      courrier_confirmation_prix: "courrier_confirmation_prix:true",
      bon_livraison: "bon_livraison:true",
      certificat_service_fait: "certificat_service_fait:true",
      situation_financiere_marche: "situation_financiere_marche:true",
      facture: "facture:true",
      montant_facture_decompte: "montant_facture_decompte:true",
      reference_facture: "reference_facture:true",
      date_facture: "date_facture:true",
      observation: "observation:true",
      numero_lettre_commande:"",
      date_etablissement_lettre_commande:"",
      date_etablissement_courrier_appel_fonds:"",
      reference_courrier_appel_fonds:"",
      date_etablissement_courrier_indication_ligne_supportant_depense:"",
      reference_courrier_indication_ligne_supportant_depense:"",
      reference_courrier_demande_proposition_prix:"",
      date_etablissement_courrier_demande_proposition_prix:"",
      date_etablissement_courrier_invitation_seance_negociation:"",
      reference_courrier_invitation_seance_negociation:"",
      date_etablissement_courrier_confirmation_prix:"",
      reference_courrier_confirmation_prix:"",
      date_etablissement_courrier_demande_cotation:"",
      reference_demande_cotation:"",
      date_etablissement_formulaire_selection:"",
      reference_formulaire_selection:"",
      date_etablissement_certificat_service_fait:"",
      reference_certificat_service_fait:"",
      date_etablissement_certificat_pour_paiement:"",
      reference_certificat_paiement:"",
      date_etablissement_bon_livraison:"",
      date_rapport:"",
      date_etablissement_ordre_mission:"",
      reference_ordre_mission:"",
      date_etablissement_lettre_invitation_formation:"",
      reference_lettre_invitation:"",
      expiration_caution_AD:"",
      date_expiration_caution_AD:"",
      expiration_caution_RG:"",
      date_expiration_caution_RG:"",
      expiration_cautionnement_definitif:"",
      date_expiration_cautionnement_definitif:"",
      date_etablissement_certif_participation:"",
      date_etablissement_cautionnement_ad:"",
      date_etablissement_cautionnement_RG:"",
      date_etablissement_cautionnement_definitif:"",
      date_etablissement_proces_verbal_reception_travaux:"",
      date_etablissement_decompte:"",
      date_attachement_constat_travaux:"",
      courrier_appel_fonds:"",
      courrier_indication_ligne_supportant_depense:"",
      courrier_demande_proposition_prix:"",
      formulaire_demande_cotation:"",
      formulaire_selection: "",
      certificat_paiement:"",
      rapport_activite:"",
      tableau_recap_depense_precedente:"",
      ordre_mission: "",
      pieces_justif_depenses_appels_fonds_precedent: "",
      lettre_invitation_formation: "",
      pieces_justificatives_depenses_engagees_pendant_formation: "",
      certificat_participation_formation_seminaire_atelier_mission: "",
      cautionnement_avance_demarrage: "",
      retenue_garantie: "",
      caution_definitif: "",
      proces_verbal_reception_travaux: "",
      attachement_y_compris_travaux: "",
    }
  }),
  watch: {
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.loading[this.uploadfile] = false
        setTimeout(
          function() {
            this.disabled = false
          }.bind(this),
          800
        )
      }
    },
    filerrors() {
      if (this.filerrors) {
        this.loading[this.uploadfile] = false
        this.notif.message = this.filerrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.disabled = false
            this.notif.activated = ""
            this.$refs[this.uploadfile]=""
            this.form[this.uploadfile]=""
            this.inputFiles[this.uploadfile]=""
            this.setFileErors("")
          }.bind(this),
          3000
        )
      }
    },
    successdecompteP() {
      if (this.successdecompteP) {
        this.notif.message = this.successdecompteP
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.$router.push({ name: "decompte" })
          }.bind(this),
          3000
        )
      }
      if (this.decompterrorsP) {
        this.notif.message = this.decompterrorsP
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    situationMarche(){
      if (this.situationMarche) {
        this.form.situation_financiere_marche = Number(this.situationMarche.situation_financiere[0].marche)
        this.form.situation_financiere_ordonnancement = Number(this.situationMarche.situation_financiere[0].ordonnancement)
        console.log(this.situationMarche)
      }
    },
    successdecompte() {
      if (this.successdecompte) {
        this.notif.message = this.successdecompte
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.$router.push({ name: "decompte" })
          }.bind(this),
          3000
        )
      }
      if (this.decompterrors) {
        this.notif.message = this.decompterrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },

  },
  created() {
    this.ListMarches(null)
    this.listExtensionFiles = utils.formaFichierAccepte
    this.getSousTraitants()
    //Recuperation de la liste des numero de decompte deja utilisé
    if(this.decomptes.donnees){
      this.decomptes.donnees.forEach(decompte =>{
        this.listNumeroDecompte.push(decompte.numero_decompte)
      })
    }

  },
  computed: {
    ...mapGetters(["marches","marche", "errors", "successdecompte", "filerrors", "soustraitants", "files", "decompterrors","decomptes","situationMarche","successdecompteP", "decompterrorsP"])
  },
  methods: {
    ...mapActions(["groupMarches", "saveDecomptes","saveDP", "getSousTraitants", "ListMarches", "saveFile","Decomptes","situationMarcheDecompte","getMarche"]),
    ...mapMutations(["setSuccess", "setErors", "setFileErors","setSuccessP", "setErrorP","setSituationMarche"]),

    handleFileUpload(filename) {
      console.log(this.$refs[filename].files)
    
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      if (!this.inputFiles[filename].includes(" ")) {
        const extension = this.inputFiles[filename].split(".")[1]
        if (this.listExtensionFiles.includes(extension)) {
          this.loading[filename] = true
          // this.disabled = true
          this.uploadfile = filename
          this.saveFile(this.$refs[filename].files[0])
        } else {
          this.inputFiles[filename] = ""
          this.notif.message = "Mauvais format du fichier !!"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            5000
          )
        }
      }else{
        this.inputFiles[filename] = ""
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    handleRapportUpload(filename) {
      this.rapports[filename].rapport = this.$refs["rapport"+filename][0].files[0].name
      console.log(this.rapports[filename].rapport)
      if (!this.rapports[filename].rapport.includes(" ")) {
        const extension = this.rapports[filename].rapport.split(".")[1]
        if (this.listExtensionFiles.includes(extension)) {
          this.loading[filename] = true
          this.disabled = true
          this.uploadfile = filename
          this.saveFile(this.$refs["rapport"+filename][0].files[0])
        } else {
          this.rapports[filename].rapport = ""
          this.notif.message = "Mauvais format du fichier !!"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            5000
          )
        }
      }else{
        this.rapports[filename].rapport = ""
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    searchMarche(id) {
      this.getMarche(id)
      this.removeSpanError('marche')
    },
    searchSituation(id_marche,id_ordonnancement) {
      this.setSituationMarche("")
      this.situationMarcheDecompte({id_marche:id_marche,id_ordonnancement:id_ordonnancement})
    },
    // getMarche(id) {
    //   this.marches.donnees.forEach(marche => {
    //     if (marche.id == id) {
    //       this.gmarche = marche
    //     }
    //   })
    // },
    recreate(value,key){
      var splinter = value.split(" ")
      this.form[key] = ""
      for (let index = 0; index < splinter.length; index++) {
        this.form[key] += splinter[index]
      }
    },
    submit() {
      this.recreate(this.form.montant_facture_decompte,'montant_facture_decompte')
      if (Number(this.form.montant_facture_decompte) <= this.form.situation_financiere_marche) {
        if (
          !this.form
            .validate()
            .errors()
            .any()
        ) {
          this.saveDecomptes(this.form.data)
        } else {
          for (let affichekey in this.affiche) {
            const tabVale = this.affiche[affichekey].split(":")
            if (tabVale.length === 1) {
              this.affiche[affichekey] = tabVale[0] + ":true"
            }
          }
        }
      }else{
        this.notif.message = "Le montant de la facture/decompte ne doit pas excéder la situation financière du marché"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      // if (
      //   !this.form
      //     .validate()
      //     .errors()
      //     .any()
      // ) {
      //   this.saveDecomptes(this.form.data)
      // } else {
      //   for (let affichekey in this.affiche) {
      //     const tabVale = this.affiche[affichekey].split(":")
      //     if (tabVale.length === 1) {
      //       this.affiche[affichekey] = tabVale[0] + ":true"
      //     }
      //   }
      // }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        this.form[key] = this[key]
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }

      this[key] = chaine
    },
    saving() {
      this.recreate(this.form.montant_facture_decompte,'montant_facture_decompte')
      this.saveDP(this.form.data)
    },
    goBack() {
      this.$router.push({ name: "decompte" })
    },
    checkExpiration(key,ekey){
      if (this[ekey]!="date") {
        this.form[key]=this[ekey]
      }else{
        this.form[key]=""
      }
      console.log(this.form[key])
    },
    removeSpanError(element) {
      for (let affichekey in this.affiche) {
        const valeur = this.affiche[affichekey].split(":")[0]
        if (valeur === element) {
          this.affiche[affichekey] = valeur
        }
      }
    },
    /**
     * La fonction verifie les numero de decomptes deja utiliser et empeche d'en utiliser le même
     */
    verifierNumeroDecompte(){
      if(this.listNumeroDecompte.includes(this.form.numero_decompte)){
        this.notif.message = "Ce numero de decompte est déjà utilisé. Veillez le changer!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            this.form.numero_decompte=""
          }.bind(this),3000)
      }
    },
    /**
     * Creation des espaces pour les extentions de fichiers
     * @returns {string}
     * @constructor
     */
    CreateEspaceBetweenFileExtentions(){
      return utils.formaFichierAccepteToString()
    }
  }
}
</script>
